<template>
  <v-dialog
    max-width="500px"
    :value="true"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>{{ $t('createPuck') }}</v-card-title>
      <v-card-text class="pb-0">
        <v-form>
          <v-text-field
            outlined
            :label="$t('name')"
            v-model="puck.name"
          />
          <v-text-field
            outlined
            :label="$t('barcode')"
            v-model="puck.barcode"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="create"
          block
          color="primary"
          large
          :loading="processing"
          :disabled="processing"
        >
          {{ $t('create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'CreatePuck',
    data() {
        return {
            puck: {
                name: '',
                barcode: ''
            },
            processing: false
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        create() {
            this.processing = true

            this.$store.dispatch('createPuck', this.puck).finally(() => {
                this.processing = false
                this.$emit('close')
            })
        }
    }
}
</script>
